import React from 'react'

import Meta from 'components/meta'
import WrapperImage from 'components/wrapper-image'

interface Props {
  location: Location
}

const Branch = ({ location }: Props) => {
  return (
    <WrapperImage background="yomo-branch-bg.jpg" effect="duotone" backgroundClass="o-80" location={location}>
      <Meta title="Branches" />
      <div id="branches" className="h-100 d-flex flex-column align-items-center justify-content-center">
        <div className="fs-5 text-center">coming soon</div>
      </div>
    </WrapperImage>
  )
}

export default Branch
