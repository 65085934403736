import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import Footer from './footer'
import Header from './header'

interface Props {
  children: React.ReactNode
  location: Location
  variant: string
  background: string
  process: string
  backgroundClass: string
  wrapperClass: string
}

const WrapperImage = ({ children, location, variant, background, effect, backgroundClass, wrapperClass }: Props) => {
  const { backgroundImages } = useStaticQuery(
    graphql`
      query {
        backgroundImages: allFile(
          filter: { sourceInstanceName: { eq: "backgrounds" }, extension: { regex: "/png|jpg/" } }
          limit: 9999
        ) {
          nodes {
            relativePath
            default: childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
            duotone: childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                transformOptions: { duotone: { highlight: "#00374B", shadow: "#0A0E29"} }
              )
            }
          }
        }
      }
    `
  )

  const imageNode = backgroundImages.nodes.find((n) => n.relativePath == background)

  return (
    <div id="wrapper" className={wrapperClass} style={{ display: 'grid' }}>
      <GatsbyImage
        className={backgroundClass}
        // image={getImage(imageNode)}
        image={getImage(imageNode[effect ?? 'default'].gatsbyImageData)}
        alt=""
        aspectRatio={1 / 1}
        formats={['auto', 'webp', 'avif']}
        style={{
          gridArea: '1/1',
        }}
      />
      <div
        style={{
          gridArea: '1/1',
          display: 'grid',
          position: 'relative',
          placeItems: 'top',
        }}
      >
        <div className="h-100">
          <header>
            <a id="home" />
            <Header location={location} variant={variant} />
          </header>
          <div className="d-flex flex-column align-items-center justify-content-center w-100 h-100">
            <main>{children}</main>
            {location?.pathname === '/' && (
              <footer>
                <Footer variant={variant} />
              </footer>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default WrapperImage
